import PropTypes from "prop-types";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { getAllGuardians, getAllStudents } from "../../util/api-calls";

import { useEffect, useState } from "react";

// project imports
import MainCard from "./ui-component/cards/MainCard";
import TotalIncomeCard from "./ui-component/cards/Skeleton/TotalIncomeCard";

import PaidIcon from "@mui/icons-material/Paid";

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: "#5cb85c",
  color: theme.palette.primary.light,
  overflow: "hidden",
  position: "relative",
  marginLeft: "10px",
  marginTop: "10px",
  borderRadius: "10px",
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#4e9c4e",
    borderRadius: "50%",
    top: -30,
    right: -180,
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#489448",
    borderRadius: "50%",
    top: -160,
    right: -130,
  },
}));

// ==============================|| DASHBOARD - TOTAL INCOME DARK CARD ||============================== //

function TotalRegistrationCard(updateDashboard, parents) {
  function getCurrentSchoolYear() {
    const date = new Date();
    let year = date.getFullYear();

    if (date.getMonth() === 11) {
      // Month in JavaScript is 0-indexed, so 11 is December
      year += 1;
    } else if (date.getMonth() === 12) {
    year += 1;
    }
    return year.toString();
  }

  const generateYearOptions = () => {
    const currentYear = getCurrentSchoolYear();
    let years = [];
    for (let year = currentYear; year >= 2023; year--) {
      years.push(year);
    }
    return years;
  };
  const [numberOfInvoices, setNumberOfInvoices] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(getCurrentSchoolYear());

  useEffect(() => {
    setLoading(true);
    // getAllGuardians().then((parents) => {
    if (parents.length > 0) {
      let count = 0;
      let totalAmount = 0;
      for (let i = 0; i < parents.length; i++) {
        for (let j = 0; j < parents[i].registration.length; j++) {
          if (
            new Date(parents[i].registration[j].updatedAt) >
            new Date("2022-12-19")
          ) {
            const updatedDate = new Date(parents[i].registration[j].updatedAt);
            let updatedYear = updatedDate.getFullYear();

            if (updatedDate.getMonth() === 11) {
              updatedYear += 1;
            }

            if (updatedYear.toString() === year) {
              count++;
              totalAmount += parseInt(parents[i].registration[j].amount_due);
            }
          }
        }
      }
      setNumberOfInvoices(count);
      setTotalAmount(totalAmount);
      setLoading(false);
      // });
    }
  }, [updateDashboard, parents, year]);

  const yearOptions = generateYearOptions();
  return (
    <>
      {loading ? (
        <TotalIncomeCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2 }}>
            <Grid item sx={{ mb: 0.5 }}>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <List sx={{ py: 0 }}>
                    <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                      <ListItemAvatar>
                        <Avatar
                          variant="rounded"
                          sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.largeAvatar,
                            backgroundColor: "#489448",
                            color: "#fff",
                          }}
                        >
                          <PaidIcon fontSize="inherit" />
                        </Avatar>
                        <FormControl
                          size="small"
                          style={{
                            marginTop: 10,
                            display: "flex",
                            minWidth: 80,
                            flex: 1,
                            marginRight: 9,
                            maxWidth: 80,
                            padding: 1,
                            borderColor: "#ffffff",
                          }}
                        >
                          <InputLabel
                            style={{
                              fontSize: 14,
                              backgroundColor: "#5cb85c",
                              color: "#FFFFFF",
                            }}
                            shrink={true}
                            id="gender-label"
                          >
                            Year
                          </InputLabel>
                          <Select
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#4e9c4e", // Set border color
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#4e9c4e", // Set border color on hover
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#4e9c4e", // Set border color when focused
                                },
                            }}
                            style={{
                              color: "#FFFFFF",
                              borderColor: "#ffffff",
                            }}
                            name="grade"
                            labelId="grade-label"
                            id="grade"
                            defaultValue={getCurrentSchoolYear()}
                            onChange={(year) => {
                              console.log(year.target.value);
                              setYear(year.target.value);
                            }}
                            label="Grade"
                          >
                            {yearOptions.map((yearOption) => (
                              <MenuItem
                                style={{
                                  fonstSize: 10,
                                  padding: 1,
                                }}
                                key={yearOption}
                                value={String(yearOption)}
                              >
                                {yearOption}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </ListItemAvatar>
                      <div
                        className="col"
                        style={{
                          marginLeft: 5,
                        }}
                      >
                        <ListItemText
                          sx={{
                            py: 0,
                            mt: 0.45,
                            mb: 0.45,
                          }}
                          primary={
                            <Typography variant="h4" sx={{ color: "#fff" }}>
                              {numberOfInvoices}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "#fff", mt: 0.25 }}
                            >
                              Registrations
                            </Typography>
                          }
                        />
                      </div>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <List sx={{ py: 0 }}>
                    <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                      <ListItemAvatar>
                        {/* <Avatar
                          variant="rounded"
                          sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.largeAvatar,
                            backgroundColor: "#1565C0",
                            color: "#fff",
                          }}
                        >
                          <PaidIcon fontSize="inherit" />
                        </Avatar> */}
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          py: 0,
                          mt: 1,
                          mb: 0.45,
                          ml: -3,
                        }}
                        primary={
                          <Typography variant="h5" sx={{ color: "#fff" }}>
                            K
                            {totalAmount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#fff", mt: 0, paddingTop: 0.9 }}
                          >
                            Total Amount
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
}

TotalRegistrationCard.propTypes = {
  isLoading: PropTypes.bool,
};

export default TotalRegistrationCard;
