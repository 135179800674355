import { Enrolment, EnrolmentPayment, Fees, Student } from "../../util/data-models";
import { v4 as uuidv4 } from "uuid";
import { enrolmentStatuses, getFeeAmountByGradeStudentGrade } from "../../util/enrolment-utils";

export const gradeTransitions = {
    ["Grade 1"]: "Grade 2",
    ["Grade 2"]: "Grade 3",
    ["Grade 3"]: "Grade 4",
    ["Grade 4"]: "Grade 5",
    ["Grade 5"]: "Grade 6",
    ["Grade 6"]: "Grade 7",
    ["Grade 7"]: "Grade 8",
    ["Grade 8"]: "Grade 9",
    ["Grade 9"]: "Grade 10",
    ["Grade 10"]: "Grade 11",
    ["Grade 11"]: "Grade 12",
    ["Grade 12"]: "Alumni",
}

export const gradesList = [
    "Grade 1",
    "Grade 2",
    "Grade 3",
    "Grade 4",
    "Grade 5",
    "Grade 6",
    "Grade 7",
    "Grade 8",
    "Grade 9",
    "Grade 10",
    "Grade 11",
    "Grade 12"
];
export type Grade = "Grade 1" | "Grade 2" | "Grade 3" | "Grade 4" | "Grade 5" | "Grade 6" | "Grade 7" | "Grade 8" | "Grade 9" | "Grade 10" | "Grade 11" | "Grade 12";


export const getLastYear = () => {
    let result = (new Date().getFullYear() - 1).toString();
    return result;
}

export const createOldEnrolmentsForStudent = (): Enrolment[] => {
    return [
        {
            id: uuidv4(),
            status: enrolmentStatuses.year,
            discount: "",
            year: getLastYear(),
            grade: "Grade 2",
            amountOwing: 0,
            totalAmount: 0,
            payments: [] as EnrolmentPayment[],
            createdAt: new Date("2021-01-01"),
        }
    ];
}

export const getNewRegistrationForStudent = (student_id: string) => {
    return [{
        id: uuidv4(),
        status: "Complete",
        discount: 0,
        year: "",
        term: "",
        paymentproof: "",
        student_id: student_id,
        amount_due: 0,
        amount_paid: 100,
    }];
}

export const createNewStudent = (firstName: string, lastName: string): Student => {
    const studentId = uuidv4();
    return {
        id: studentId,
        firstname: firstName,
        lastname: lastName,
        middlename: "",
        gender: Math.random() > 0.5 ? "Male" : "Female",
        dob: "2010-10-10",
        address: "Main " + Math.random() * 1000,
        town: "Townsville",
        province: "Tirol",
        number: "123456789",
        email: "abc@mail.com",
        nationality: "Tuvalu",
        language: "English",
        grade: "Grade 3",
        class: "A",
        teacher: "",
        schoolfee: "",
        ptafee: "",
        pastinvoices: "",
        parentsguardians: "",
        lastschool: "",
        lastschoolcity: "",
        lastschoolprovince: "",
        lastschoolnumber: "",
        lastgradecompleted: "",
        emergencyfirstname: "",
        emergencylastname: "",
        emergencyrelationship: "",
        emergencyaddress: "",
        emergencytown: "",
        emergencynumber: "",
        medicalconditions: "",
        medicalallergies: "",
        medicalmedications: "",
        medicalother: "",
        parentsguardiansrelationship: "",
        custody: "",
        studentliveswith: "",
        alumni: "",
        transferredout: "",
        droppedout: "",
        leftstudentcomment: "",
        guardians: [],//createGuardiansForStudent(studentId),
        enrolments: createOldEnrolmentsForStudent(),
        createdAt: new Date(Date.now()).toISOString(),
        updatedAt: new Date(Date.now()).toISOString()
    }
}

export const getEnrolmentYear = () => {
    let result = new Date().getFullYear().toString();
    if (new Date().getMonth() >= 11) {
        result = (new Date().getFullYear() + 1).toString();
    }
    return result;
};

export const getNewEnrolment = (student: Student, fees: Fees): Enrolment => {
    return {
        id: uuidv4(),
        status: enrolmentStatuses.pending,
        discount: "0",
        year: getEnrolmentYear(),
        grade: student.grade,
        amountOwing: getFeeAmountByGradeStudentGrade(student, fees),
        totalAmount: getFeeAmountByGradeStudentGrade(student, fees),
        payments: [] as EnrolmentPayment[],
        createdAt: new Date(),
    }
}

export const studentGradeCounter = (students: Student[], grade: string) => {
    return students.reduce((count, student) => {
        if (student.grade === grade) {
            return count + 1;
        }
        return count;
    }, 0)
}

export const isDateOlderThan9Months = (date: Date): boolean => {
    let diff = ((new Date()).getTime() - date.getTime()) / 1000; //diff in seconds
    diff = diff / (60 * 60 * 24 * 10 * 3); //diff in months
    return Math.abs(Math.round(diff)) > 6;
}

export const getMostRecentEnrolment = (enrolments: Enrolment[]): Enrolment => {
    return enrolments.reduce((e1, e2) => {
        return new Date(e1.createdAt) > new Date(e2.createdAt) ? e1 : e2;
    });
}

export const isStudentEligibleForReEnrolment = (student: Student): boolean => {
    if (
        (student.grade !== "Alumni" &&
        student.transferredout !== "Yes" &&
        student.droppedout !== "Yes") && 
        (student.enrolments?.length === 0 || 
            (student.enrolments?.length > 0 && 
                isDateOlderThan9Months(new Date(getMostRecentEnrolment(student.enrolments).createdAt))))
    ) {
	console.log("Advance: ", student.grade, student.firstname, student.lastname)
        return true
    } else {
        return false
    }   
}

export const getNumberOfEligibleStudentsForGrade = (students: Student[], grade: string, fees: Fees) => {
    return students.filter(student => {
        return student.grade === grade && isStudentEligibleForReEnrolment(student);
    }).length;
}

export const getNewGradeFromCurrentGrade = (grade: Grade) => {
    return gradeTransitions[grade];
}


const createGuardiansForStudent = (student_id: string) => {
    const newGuardian = {
        id: uuidv4(),
        student_ids: "",
        fathermother: "",
        parentlegalguardian: "",
        biologicaladoptedfoster: "",
        title: "",
        firstname: "Parent" + Math.round(Math.random() * 100),
        lastname: "Testerson" + Math.round(Math.random() * 100),
        middlename: "",
        relationship: "",
        address: "",
        town: "",
        province: "",
        number: "",
        email: "",
        phone: "",
        employer: "",
        business: "",
        createdAt: new Date(Date.now()).toISOString(),
        updatedAt: new Date(Date.now()).toISOString(),
        registration: getNewRegistrationForStudent(student_id)
    }
    return [newGuardian];
}
